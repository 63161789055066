import Head from "next/head";

import { meta } from "#constants/meta";

interface MetaProps
  extends Omit<React.ButtonHTMLAttributes<HTMLMetaElement>, "key"> {
  content?: string | null;
  unique?: meta;
}
/**
 * Wrapper for meta tags, to not include them if they are empty.
 *
 * from nextJS docs: (https://nextjs.org/docs/pages/api-reference/components/head)
 * title, meta or any other elements (e.g. script) need to be contained as direct children of the Head element, or wrapped into maximum one level of <React.Fragment> or arrays—otherwise the tags won't be correctly picked up on client-side navigations.
 *
 * Note that NextJS head tags are super wierd, so these MUST NOT
 * be used inside a <Head> tag (see above) - they provide their own.
 */
export const Meta: React.FC<MetaProps> = ({ content, unique, ...props }) => {
  if (!content || content === "") {
    return null;
  }
  if (unique) {
    return (
      <Head>
        <meta key={unique} content={content} {...props} />
      </Head>
    );
  }
  return (
    <Head>
      <meta content={content} {...props} />
    </Head>
  );
};
